import tutorial_1 from "../../assets/images/jpgs/tutorials/tutorial_1.jpeg";
import tutorial_2 from "../../assets/images/jpgs/tutorials/tutorial_2.jpeg";
import tutorial_3 from "../../assets/images/jpgs/tutorials/tutorial_3.jpeg";
import tutorial_4 from "../../assets/images/jpgs/tutorials/tutorial_4.jpeg";
import tutorial_5 from "../../assets/images/svgs/tutorials/tutorial_5.svg";
import tutorial_6 from "../../assets/images/svgs/tutorials/tutorial_6.svg";
import tutorial_7 from "../../assets/images/svgs/tutorials/tutorial_7.svg";
import tutorial_8 from "../../assets/images/svgs/tutorials/tutorial_8.svg";
import tutorial_9 from "../../assets/images/svgs/tutorials/tutorial_9.svg";
import tutorial_10 from "../../assets/images/svgs/tutorials/tutorial_10.svg";
// import tutorial_11 from "../../assets/images/svgs/tutorials/tutorial_11.svg";
import tutorial_12 from "../../assets/images/svgs/tutorials/tutorial_12.svg";
import tutorial_13 from "../../assets/images/svgs/tutorials/tutorial_13.svg";
import tutorial_14 from "../../assets/images/svgs/tutorials/tutorial_14.svg";
import tutorial_15 from "../../assets/images/jpgs/tutorials/tutorial_15.jpeg";
import tutorial_16 from "../../assets/images/jpgs/tutorials/tutorial_16.jpeg";
import tutorial_17 from "../../assets/images/jpgs/tutorials/tutorial_17.jpeg";
import tutorial_18 from "../../assets/images/jpgs/tutorials/tutorial_18.jpeg";
import tutorial_19 from "../../assets/images/jpgs/tutorials/tutorial_19.jpeg";
import tutorial_20 from "../../assets/images/jpgs/tutorials/tutorial_20.jpeg";
import tutorial_21 from "../../assets/images/jpgs/tutorials/tutorial_21.jpeg";
import tutorial_22 from "../../assets/images/jpgs/tutorials/tutorial_22.jpeg";
import tutorial_23 from "../../assets/images/jpgs/tutorials/tutorial_23.jpeg";
import tutorial_24 from "../../assets/images/jpgs/tutorials/tutorial_24.jpeg";
import tutorial_25 from "../../assets/images/jpgs/tutorials/tutorial_25.jpeg";
import tutorial_26 from "../../assets/images/jpgs/tutorials/tutorial_26.jpeg";
import tutorial_27 from "../../assets/images/jpgs/tutorials/tutorial_27.jpeg";
import tutorial_28 from "../../assets/images/jpgs/tutorials/tutorial_28.jpeg";
import tutorial_29 from "../../assets/images/jpgs/tutorials/tutorial_29.jpeg";
import tutorial_30 from "../../assets/images/jpgs/tutorials/tutorial_30.jpeg";
import tutorial_31 from "../../assets/images/jpgs/tutorials/tutorial_31.jpeg";

import { DBT_NAME } from "../../constant";

export const tutorialData = [
  {
    title: <>Understand {DBT_NAME} model and update it with natural language</>,
    description: (
      <>
        In this video, Patricia will take you on a journey to understand code
        and business logic of existing {DBT_NAME} model, and make the changes to{" "}
        {DBT_NAME} model as per business requirements. Here are steps that she
        has covered: understand the {DBT_NAME} model code better, generate code
        for {DBT_NAME} model updates, make adjustments to the code as needed
      </>
    ),
    tags: ["development", "query explanation", "dbt model update", "gen AI"],
    youtubeLink: "https://youtu.be/D2I_1MzdZL8",
    thumbnail: <img src={tutorial_1} alt="tutorial_1" />,
    youtubeEmbed: (
      <iframe
        width="763"
        height="429"
        src="https://www.youtube.com/embed/D2I_1MzdZL8"
        title={`Understand ${DBT_NAME} model and update it with natural language`}
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    ),
  },
  {
    title: <>Create a data mart in {DBT_NAME} from scratch</>,
    description: (
      <>
        Many times analytics engineers need to quickly put data marts together.
        Patricia will walk us through how easy it is to get the basic data mart
        going. Following are some interesting tips that she has covered in the
        video: generate staging model code from source file, auto-complete
        referenced {DBT_NAME} model names, and generate descriptions for $
        {DBT_NAME} models / columns.
      </>
    ),
    tags: [
      "data marts",
      "dbt documentation",
      "dbt models",
      "gen AI",
    ],
    youtubeLink: "https://youtu.be/v78f9acZ5l0",
    thumbnail: <img src={tutorial_2} alt="tutorial_2" />,
    youtubeEmbed: (
      <iframe
        width="763"
        height="429"
        src="https://www.youtube.com/embed/jeluUHOsces"
        title={`Create a data mart in ${DBT_NAME} from scratch`}
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    ),
  },
  {
    title: "dbt model debug - Model validation and query analysis",
    description: (
      <>
        In our world of data development, many times more efforts and time are
        spent in debugging existing code than actual writing new code - and $
        {DBT_NAME} code is not an exception. In this video, Patricia will cover
        how to debug {DBT_NAME} model for SQL related errors like non-existant
        columns and preview data with some charting to make sure right results
        are produced by the {DBT_NAME} model.
      </>
    ),
    tags: ["testing", "preview query results", "sql validator"],
    youtubeLink: "https://youtu.be/NFZG8ZJr5OM",
    thumbnail: <img src={tutorial_3} alt="tutorial_3" />,
    youtubeEmbed: (
      <iframe
        width="763"
        height="429"
        src="https://www.youtube.com/embed/RZHpEN5g460"
        title="dbt model debug - Model validation and query analysis"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    ),
  },
  {
    title: "dbt model debug - Column lineage and Project Health Check",
    description: (
      <>
        In this video, join Patricia on our ongoing journey into the world of
        data model debugging. Learn how to effectively analyze the impact of
        changes in your downstream models using column lineage. Discover
        essential techniques for conducting sanity checks on your {DBT_NAME}{" "}
        model, ensuring all columns are accounted for, and verifying
        documentation completeness.
      </>
    ),
    tags: [
      "testing",
      "column lineage",
      "project health check",
      "dbt documentation",
    ],
    youtubeLink: "https://youtu.be/ahsQiIJGekU",
    thumbnail: <img src={tutorial_4} alt="tutorial_4" />,
    youtubeEmbed: (
      <iframe
        width="763"
        height="429"
        src="https://www.youtube.com/embed/ahsQiIJGekU"
        title="dbt model debug - Column lineage and Project Health Check"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    ),
  },

  {
    title: "defer running upstream models in development",
    description: (
      <>
        Defer is the functionality that’s underutilized by many data teams when
        it comes to {DBT_NAME} development. Defer functionality in {DBT_NAME}{" "}
        allows the user to run a subset of models or tests without having to
        first build their upstream parents (more info) - leading to significant
        time and cost savings. In this tutorial, learn how to enable it and use
        it during the {DBT_NAME} development.
      </>
    ),
    tags: ["testing", "development", "collaboration", "defer to prod"],
    youtubeLink: "https://youtu.be/lBklxVaRRi0",
    thumbnail: <img src={tutorial_5} alt="tutorial_5" />,
    youtubeEmbed: (
      <iframe
        width="763"
        height="429"
        src="https://www.youtube.com/embed/lBklxVaRRi0"
        title="defer running upstream models in development"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    ),
  },

  {
    title: "Don’t write, but generate data quality tests!",
    description: (
      <>
        Writing tests is an additional chore that many of us would like to wrap
        quickly, but it’s an essential step before the {DBT_NAME} model is used
        to make sure data is being transformed accurately and consistently.
        Patricia will walk us through how to generate default and custom $
        {DBT_NAME} tests (from packages, macros) as well as update or delete
        existing tests.
      </>
    ),
    tags: ["testing", "gen AI"],
    youtubeLink: "https://youtu.be/0SjPa_joSns",
    thumbnail: <img src={tutorial_6} alt="tutorial_6" />,
    youtubeEmbed: (
      <iframe
        width="763"
        height="429"
        src="https://www.youtube.com/embed/0SjPa_joSns"
        title="Don’t write, but generate data quality tests!"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    ),
  },
  {
    title: "dbt Model Contracts",
    description: (
      <>
        Join Patricia as she shares her experience of a classic data management
        challenge—unexpected changes in source data that disrupted her
        downstream {DBT_NAME} models. In this insightful video, she details how
        this issue prompted her to implement {DBT_NAME} contracts to establish
        strict data type guardrails. Plus, don’t miss her expert tip on using
        Power User for {DBT_NAME} to quickly populate data types in YAML files,
        enabling rapid setup of data contracts.
      </>
    ),
    tags: ["testing", "development"],
    youtubeLink: "https://youtu.be/FPgbcrHMlt4",
    thumbnail: <img src={tutorial_7} alt="tutorial_1" />,
    youtubeEmbed: (
      <iframe
        width="763"
        height="429"
        src="https://www.youtube.com/embed/FPgbcrHMlt4"
        title="dbt Model Contracts"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    ),
  },
  {
    title: "Code and docs collaboration",
    description: (
      <>
        When you're crafting {DBT_NAME} code or documentation, the need to
        quickly consult with stakeholders for additional information or reviews
        is common. Currently, this process is disjointed, spread across multiple
        tools. Not anymore. In this video, Patricia introduces DataPilot's
        remarkable collaboration features, seamlessly integrating IDE and SaaS
        UI to enhance interactions between technical and non-technical
        stakeholders
      </>
    ),
    tags: ["collaboration", "development", "dbt documentation"],
    youtubeLink: "https://youtu.be/u-bOJ8Tztls",
    thumbnail: <img src={tutorial_8} alt="tutorial_8" />,
    youtubeEmbed: (
      <iframe
        width="763"
        height="429"
        src="https://www.youtube.com/embed/u-bOJ8Tztls?si=wNSCz7VNe4GLKYRp"
        title="Code and docs collaboration"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    ),
  },
  {
    title: <>Follow best practices in {DBT_NAME} development</>,
    description: (
      <>
        Are you a developer who often gets caught up in the excitement of
        reaching the finish line, sometimes at the expense of best practices? As
        projects grow, neglecting these best practices can lead to issues down
        the road. In this insightful video, Patricia guides us through how to
        easily check for best practices in your {DBT_NAME} projects and how to
        make necessary corrections along the way.
      </>
    ),
    tags: ["collaboration", "testing", "development", "governance"],
    youtubeLink: "https://youtu.be/RMrKCSaU3_A",
    thumbnail: <img src={tutorial_9} alt="tutorial_9" />,
    youtubeEmbed: (
      <iframe
        width="763"
        height="429"
        src="https://www.youtube.com/embed/RMrKCSaU3_A?si=yUelupYwT9GMWgRv"
        title={`Follow best practices in ${DBT_NAME} development`}
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    ),
  },
  {
    title: <>DataPilot CLI usage for {DBT_NAME} Project Governance</>,
    description: (
      <>
        This video is a follow-up to the previous video titled “Follow best
        practices in {DBT_NAME} Development” where Patricia dives deeper into
        configuring governance checks using YAML file configuration. She also
        demonstrates how to utilize the DataPilot CLI and the Power User VSCode
        extension so that these checks can be performed in various stages of
        development.
      </>
    ),
    tags: ["testing", "development"],
    youtubeLink: "https://youtu.be/QT0N6svh-5o",
    thumbnail: <img src={tutorial_10} alt="tutorial_10" />,
    youtubeEmbed: (
      <iframe
        width="763"
        height="429"
        src="https://www.youtube.com/embed/QT0N6svh-5o?si=QwL1_hItE3eg1QLN"
        title={`DataPilot CLI usage for ${DBT_NAME} Project Governance`}
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    ),
  },
  {
    title:
      "Trace impact of code changes in column lineage with code visibility",
    description:
      "Join Patricia as she demonstrates how to prevent your code changes from disrupting downstream models. She will guide you through tracing specific column changes in downstream models and show you how to easily access the exact code snippets responsible for these transformations using the column lineage view.",
    tags: ["testing", "column lineage", "dbt model update"],
    youtubeLink: "https://youtu.be/PcFoPFKhH3I",
    thumbnail: <img src={tutorial_12} alt="tutorial_12" />,
    youtubeEmbed: (
      <iframe
        width="763"
        height="429"
        src="https://www.youtube.com/embed/PcFoPFKhH3I?si=QwL1_hItE3eg1QLN"
        title="Trace impact of code changes in column lineage with code visibility"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    ),
  },
  {
    title: "Graphically visualize your SQL for refactoring or debugging",
    description: (
      <>
        As engineers, a significant portion of our time is put into debugging
        and enhancing existing code. In this video, Patricia demonstrates how to
        get visual representation your {DBT_NAME} or SQL code, providing a clear
        view of its structure and the connections between different components.
        She also showcases how this info can be easily shared via UI with fellow
        developers and QA engineers.
      </>
    ),
    tags: ["collaboration", "testing", "development", "sql validator"],
    youtubeLink: "https://youtu.be/0wfJJ4OVDEA",
    thumbnail: <img src={tutorial_13} alt="tutorial_13" />,
    youtubeEmbed: (
      <iframe
        width="763"
        height="429"
        src="https://www.youtube.com/embed/0wfJJ4OVDEA?si=UJBtOLURfbQjc-w2"
        title="Graphically visualize your SQL for refactoring or debugging"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    ),
  },
  {
    title: "Bookmark your favorite queries and share with others",
    description: (
      <>
        Master the art of data validation for {DBT_NAME} models with Patricia's
        expert guidance. Learn to craft ad-hoc queries that verify your{" "}
        {DBT_NAME} model outputs and track changes during development. This
        video also covers efficient query history management, including
        bookmarking queries and sharing those bookmarks with your team,
        enhancing your collaborative data analytics workflow
      </>
    ),
    tags: ["collaboration", "testing", "bookmark", "share queries"],
    youtubeLink: "https://youtu.be/VzXnxl77Lq0",
    thumbnail: <img src={tutorial_14} alt="tutorial_14" />,
    youtubeEmbed: (
      <iframe
        width="763"
        height="429"
        src="https://www.youtube.com/embed/VzXnxl77Lq0?si=UJBtOLURfbQjc-w2"
        title="Bookmark your favorite queries and share with others"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    ),
  },
  {
    title: "Notebooks for ad-hoc data analysis",
    description: (
      <>
        Notebooks in DataPilot streamline data validation and analysis, whether you're working with raw tables or freshly updated dbt models. This powerful feature combines jinja-SQL and Python code blocks in a single file, creating reusable recipes for data tasks that can be shared across the organization.
      </>
    ),
    tags: ["notebooks", "dbt models", "ad-hoc query", "datapilot"],
    youtubeLink: "https://www.youtube.com/watch?v=CA7bTsHdwZA&t=8s",
    thumbnail: <img src={tutorial_15} alt="tutorial_15" />,
    youtubeEmbed: (
      <iframe width="560" height="315" src="https://www.youtube.com/embed/CA7bTsHdwZA?si=JJpaFWHz5Q5pDFKp" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    ),
  },  
  {
    title: "Column lineage with code visibility",
    description: (
      <>
        Track how your column has changed with column lineage and transformation code visibility
      </>
    ),
    tags: ["column lineage", "datapilot", "code visibility"],
    youtubeLink: "https://www.youtube.com/watch?v=n13YDOJ4Rno",
    thumbnail: <img src={tutorial_16} alt="tutorial_16" />,
    youtubeEmbed: (
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/n13YDOJ4Rno?si=8eVa6fxokFQUWFGI"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
    ),
  },  
  {
    title: "Query Bookmarks and Query History for dbt and SQL",
    description: (
      <>
        This functionality stores the history of all the queries you ran, you can create bookmarks and share bookmarks with other users in your Altimate AI instance.
      </>
    ),
    tags: ["query store", "Query Store for dbt", "Query Store for SQL", "bookmark"],
    youtubeLink: "https://www.youtube.com/watch?v=1TPAyE5EJ5Y",
    thumbnail: <img src={tutorial_17} alt="tutorial_17" />,
    youtubeEmbed: (
      <iframe width="560" height="315" src="https://www.youtube.com/embed/1TPAyE5EJ5Y?si=lPodez0k9hvgwzIX" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    ),
  },  
  {
    title: "Use dbt Cloud in VSCode seamlessly with the Power User extension",
    description: (
      <>
        Now, dbt Cloud users can develop in VSCode seamlessly with Power User VSCode extension and also accelerate the development with dbt models, tests, and docs generation
        This functionality is still in beta, please check more details in the docs
      </>
    ),
    tags: ["dbt", "power user extension", "datapilot", "dbt cloud", "CI/CD"],
    youtubeLink: "https://www.youtube.com/watch?v=NagRG2uV5m8&t=4s",
    thumbnail: <img src={tutorial_18} alt="tutorial_18" />,
    youtubeEmbed: (
      <iframe width="560" height="315" src="https://www.youtube.com/embed/NagRG2uV5m8?si=EImwbrTIqtdoWP2d" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    ),
  },
  {
    title: "dbt Column lineage with code visibility",
    description: (
      <>
        Use real-time dbt Column Lineage to understand the impact of your changes and troubleshoot dbt Models with easy visibility into code snippets
      </>
    ),
    tags: ["query store", "Query Store for dbt", "Query Store for SQL", "bookmark"],
    youtubeLink: "https://www.youtube.com/watch?v=8JjmDjOcUo4",
    thumbnail: <img src={tutorial_19} alt="tutorial_19" />,
    youtubeEmbed: (
      <iframe width="560" height="315" src="https://www.youtube.com/embed/8JjmDjOcUo4?si=Zwp0RmDthPdppZMv" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    ),
  },
  {
    title: "DataPilot - View dbt Documentation and Column Lineage with transformations in SaaS UI",
    description: (
      <>
        Now, you can view all your dbt documentation and column lineage with transformation info in DataPilot SaaS UI.
      </>
    ),
    tags: ["column lineage", "dbt models", "datapilot", "dbt documentation"],
    youtubeLink: "https://www.youtube.com/watch?v=JUuQM6Hxcwg&t=99s",
    thumbnail: <img src={tutorial_20} alt="tutorial_20" />,
    youtubeEmbed: (
      <iframe width="560" height="315" src="https://www.youtube.com/embed/JUuQM6Hxcwg?si=_ZZQlOovkTtBeGQb" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    ),
  },
  {
    title: "Check dbt best practices in IDE, Git, CI/CD",
    description: (
      <>
        Project governance functionality lets you swiftly scan all dbt projects against dbt best practices and organizational guidelines in IDE, Git, CI/CD. You can also create different check configurations based on projects, environments, and teams.
      </>
    ),
    tags: ["dbt", "dbt models", "CI/CD", "datapilot"],
    youtubeLink: "https://www.youtube.com/watch?v=3nJ9otNNIwQ",
    thumbnail: <img src={tutorial_21} alt="tutorial_21" />,
    youtubeEmbed: (
      <iframe width="560" height="315" src="https://www.youtube.com/embed/3nJ9otNNIwQ?si=sfvgWunq1kEtbB29" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    ),
  },
  {
    title: "Collaborate on dbt documentation and code via IDE and UI",
    description: (
      <>
        Collab functionality enables you to discuss code and documentation easily with the stakeholders via VSCode and UI. Many stakeholders are not comfortable using IDE directly; this functionality enables them also to have a discussion with technical users.
      </>
    ),
    tags: ["dbt documentation", "dbt", "dbt models", "collaboration", "datapilot"],
    youtubeLink: "https://www.youtube.com/watch?v=pI1U94j-pOI",
    thumbnail: <img src={tutorial_22} alt="tutorial_22" />,
    youtubeEmbed: (
      <iframe width="560" height="315" src="https://www.youtube.com/embed/pI1U94j-pOI?si=p05TLi3QHlc02q22" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    ),
  },
  {
    title: "dbt Documentation Generation",
    description: (
      <>
        Generate descriptions for your dbt models and columns in different languages and as per personas.
      </>
    ),
    tags: ["dbt", "dbt models", "dbt documentation", "datapilot"],
    youtubeLink: "https://www.youtube.com/watch?v=8EuBDEuYZ2o",
    thumbnail: <img src={tutorial_23} alt="tutorial_23" />,
    youtubeEmbed: (
      <iframe width="560" height="315" src="https://www.youtube.com/embed/8EuBDEuYZ2o?si=FIGHbGFTZ55xK1JM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    ),
  },
  {
    title: "defer to prod in dbt with VSCode",
    description: (
      <>
        Deferring to prod functionality in dbt saves data teams a lot of time and money. The Power User extension makes it easy to use this functionality in local and SaaS modes.
      </>
    ),
    tags: ["defer to prod", "datapilot", "dbt", "dbt models", "VSCode"],
    youtubeLink: "https://www.youtube.com/watch?v=r91_NShqhlU",
    thumbnail: <img src={tutorial_24} alt="tutorial_24" />,
    youtubeEmbed: (
      <iframe width="560" height="315" src="https://www.youtube.com/embed/r91_NShqhlU?si=UY8Gwt8FuyytGUkK" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    ),
  },
  {
    title: "dbt Column Lineage",
    description: (
      <>
        Use dbt Column Lineage to understand the impact of your changes and troubleshoot dbt Models.
      </>
    ),
    tags: ["column lineage", "dbt", "dbt models"],
    youtubeLink: "https://www.youtube.com/watch?v=qdHy2Y3-Oew",
    thumbnail: <img src={tutorial_25} alt="tutorial_25" />,
    youtubeEmbed: (
      <iframe width="560" height="315" src="https://www.youtube.com/embed/qdHy2Y3-Oew?si=8a8hqdSru_ictU4L" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    ),
  },
  {
    title: "Generate and edit dbt tests",
    description: (
      <>
        Generate code for your dbt tests, and edit and delete those tests quickly in VSCode UI without touching the YAML files in the dbt project.
      </>
    ),
    tags: ["dbt", "dbt tests", "dbt models", "datapilot"],
    youtubeLink: "https://www.youtube.com/watch?v=VunQngGe-JE",
    thumbnail: <img src={tutorial_26} alt="tutorial_26" />,
    youtubeEmbed: (
      <iframe width="560" height="315" src="https://www.youtube.com/embed/VunQngGe-JE?si=Iown3yR59_0Ve2kQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    ),
  },
  {
    title: "Query translation demo",
    description: (
      <>
        Do you want to translate SQL queries from one dialect (SQL Server) to another (Snowflake)? Look no further; this video will show you how to do it easily. There are 15 different SQL dialects supported, and the DataPilot provides a translation explanation.
      </>
    ),
    tags: ["query translation", "dbt", "dbt models", "datapilot"],
    youtubeLink: "https://www.youtube.com/watch?v=ssLpEFVNE3c",
    thumbnail: <img src={tutorial_27} alt="tutorial_27" />,
    youtubeEmbed: (
      <iframe width="560" height="315" src="https://www.youtube.com/embed/ssLpEFVNE3c?si=l-UbRqG6JSYGsb2F" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    ),
  },
  {
    title: "Update dbt Models using DataPilot",
    description: (
      <>
        Many times, you need to add additional columns or change transformation in your dbt Model. Just ask for the changes in natural language, and DataPilot can generate the dbt model update code in VSCode itself.
      </>
    ),
    tags: ["dbt", "dbt models", "datapilot", "dbt tests"],
    youtubeLink: "https://www.youtube.com/watch?v=TqyNhLTRD0M",
    thumbnail: <img src={tutorial_28} alt="tutorial_28" />,
    youtubeEmbed: (
      <iframe width="560" height="315" src="https://www.youtube.com/embed/TqyNhLTRD0M?si=hdiQeFeHBAzcw34D" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    ),
  },
  {
    title: "SQL Query / dbt Model explanation",
    description: (
      <>
        Query explanation is invaluable to understanding a complex piece of dbt or SQL code (especially written by others!). You can ask follow-up questions in the chat window, like - "Explain this function with some sample data" as well.
      </>
    ),
    tags: ["dbt", "dbt models", "query explanation", "datapilot", "dbt tests"],
    youtubeLink: "https://www.youtube.com/watch?v=OfAmF9cJHEw",
    thumbnail: <img src={tutorial_29} alt="tutorial_29" />,
    youtubeEmbed: (
      <iframe width="560" height="315" src="https://www.youtube.com/embed/OfAmF9cJHEw?si=15SF4kc3queGpdJY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    ),
  },
  {
    title: "Use dbt Cloud in VSCode with the Power User Extension",
    description: (
      <>
        This video show steps to enable development for dbt Cloud projects in VSCode with the help of Power User extension.
      </>
    ),
    tags: ["dbt", "dbt Cloud", "dbt models", "dbt tests", "VSCode", "datapilot"],
    youtubeLink: "https://www.youtube.com/watch?v=ECcxT6lC30A&t",
    thumbnail: <img src={tutorial_30} alt="tutorial_30" />,
    youtubeEmbed: (
      <iframe width="560" height="315" src="https://www.youtube.com/embed/ECcxT6lC30A?si=kQxpvNZi1Em4CmPW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    ),
  },
  {
    title: "Simplify Ad Hoc Analysis in dbt with Data Visualization",
    description: (
      <>
        Discover how to streamline ad hoc analysis with DBT and Python, solving common challenges like working with raw data, unnesting JSON arrays, and creating clear visualizations. This video shows you how to transform complex datasets into actionable insights quickly and efficiently, making data analysis more intuitive and impactful. Perfect for tackling real-world problems and improving your workflow!
      </>
    ),
    tags: ["dbt", "dbt Cloud", "dbt models", "ad-hoc query", "datapilot"],
    youtubeLink: "https://www.youtube.com/watch?v=4Zu1CD5GyZo&t",
    thumbnail: <img src={tutorial_31} alt="tutorial_31" />,
    youtubeEmbed: (
      <iframe width="560" height="315" src="https://www.youtube.com/embed/4Zu1CD5GyZo?si=QTG1ubBrZHZlFRvh" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    ),
  },
];
